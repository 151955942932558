import React from "react"
import styled from "styled-components"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { Link } from "gatsby"
import {  Section, SectionTitle, SectionPageTitle, BreadCrumb  } from "../components/section"
import ColorBoard from "../components/ColorBoard"
import BreakpointUp from "../components/Media/BreakpointUp"

const WhiteBox = styled.div`
	border:1px solid #E5E5E5;
	background-color:#fff;
    padding:30px 15px;	
    ${BreakpointUp.xl`		
		padding:60px 30px;
	`}
    h2{
        font-size: 24px;
        line-height: 34px;
        margin-bottom:30px;
    }
    ul{
        list-style: none;
        margin: 0 auto;
        padding: 0;
        max-width: 968px;
        width: 100%;
        text-align: center;
    }
`
const ColorOptionsPage = () => (
    <Layout>
      <SEO title="Metal Buildings Color Options, Color Visualizer and Color Selector" description="Mr.Carports now offers a variety of color choices to choose from when selecting your roof color, trim color, and wall color!" />
        <Section pt="140px" pb="255px" xpt="110px" xpb="60px" bg="#F4F8FC">		
			<div className="container">
                <BreadCrumb mb="10px"><Link to='/'>Home</Link>/<span>Color Options</span></BreadCrumb>
				<SectionPageTitle>Color Options</SectionPageTitle>				
			</div>	
		</Section> 
		<Section pb="90px" xpt="60px" xpb="60px" mt="-240px" xmt="0" bg="transparent">
			<div className="container">
                <WhiteBox>
                    <SectionTitle>Available Colors</SectionTitle>
                    <ColorBoard />
                </WhiteBox>
			</div>
    	</Section> 
    </Layout>
)

export default ColorOptionsPage
